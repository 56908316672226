.fallback-spinner{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.app-loader {
    height: 100vh;
    flex-direction: column;
    .loading {
      margin-top: 1rem;
    }
  }

  .loading {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    .effect-1,
    .effect-2,
    .effect-3 {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-left: 3px solid darkgreen;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    .effect-1 {
      position: absolute;
      animation: rotate 1s ease infinite;
    }
    .effect-2 {
      position: absolute;
      animation: rotateOpacity 1s ease infinite 0.1s;
    }
    .effect-3 {
      -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
      animation: rotateOpacity 1s ease infinite 0.2s;
    }

    .loading .effects {
      transition: all 0.3s ease;
    }
  }
}

.qr_spinner{
  .loading {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    .effect-1,
    .effect-2,
    .effect-3 {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-left:  3px solid darkgreen;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
}
}
.send_spinner{

  .loading {
    width: 24px;
    height: 18px;
    border-radius: 50%;
    border: 3px solid transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    .effect-1,
    .effect-2,
    .effect-3 {
      width: 24px;
      height: 18px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-left: 3px solid rgb(255, 255, 255);
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
}
}

.qr_spinner{
  .loading {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    .effect-1,
    .effect-2,
    .effect-3 {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-left:  3px solid darkgreen;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
}
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
